@theme-color-vpit: #ffb033;
@theme-color-highlight-vpit: darken(spin(@theme-color-vpit, -60), 30%);
@theme-color-lightest-vpit: lighten(@theme-color-vpit, (95% - lightness(@theme-color-vpit)));
@theme-color-lighter-vpit: lighten(@theme-color-vpit, (90% - lightness(@theme-color-vpit)));
@theme-color-light-vpit: lighten(@theme-color-vpit, (85% - lightness(@theme-color-vpit)));
@theme-color-darkest-vpit: darken(@theme-color-vpit, 40%);
@theme-color-darker-vpit: darken(@theme-color-vpit, 20%);
@theme-color-dark-vpit: darken(@theme-color-vpit, 15%);
@link-color-vpit: @theme-color-dark-vpit;
@link-hover-color-vpit: @theme-color-darker-vpit;
@headings-color-vpit: @theme-color-darkest-vpit;
@table-border-color-vpit: @theme-color-light-vpit;
@table-bg-accent-vpit: @theme-color-lightest-vpit;
@table-bg-hover-vpit: @theme-color-lightest-vpit;
@breadcrumb-bg-vpit: @theme-color-lightest-vpit;
@breadcrumb-color-vpit: @link-color-vpit;
@breadcrumb-active-color-vpit: @theme-color-highlight-vpit;
.pause-vpit { a { color:  @link-color-vpit; }}
.pause-vpit { a:hover { color:  @link-hover-color-vpit; }}
.pause-vpit { h1,h2,h3,h4,h5,h6 { color: @headings-color-vpit; }}
.pause-vpit { .table th,.table td { border: 1px solid @table-border-color-vpit; }}
.pause-vpit { .table tbody + tbody { border-top: 2px solid @table-border-color-vpit; }}
.pause-vpit { .table-bordered { border: 1px solid @table-border-color-vpit; }}
.pause-vpit { .table-bordered th, .table-bordered td { border: 1px solid @table-border-color-vpit; }}
.pause-vpit { .table-striped tbody > tr:nth-child(even) > td, .table-striped tbody > tr:nth-child(even) > th { background-color: @table-bg-accent-vpit; }}
.pause-vpit { .table-hover tbody > tr:hover td, .table-hover tbody tr:hover > th { background-color: @table-bg-hover-vpit; }}
.pause-vpit { table.tablesorter thead tr th, table.tablesorter thoot tr th { background-color: @theme-color-lighter-vpit; }}
.pause-vpit { table.tablesorter { border-color: @theme-color-lighter-vpit; }}
.pause-vpit { .breadcrumb { background-color: @breadcrumb-bg-vpit; }}
.pause-vpit { .breadcrumb { .active { color: @breadcrumb-active-color-vpit; }}}
.pause-vpit { .alert-info { color: @theme-color-dark-vpit; }}
.pause-vpit { .alert-info { background-color: @theme-color-lightest-vpit; }}
.pause-vpit { .alert-info { border-color: @theme-color-light-vpit; }}
.pause-vpit { .navbar-default { background-color: @theme-color-vpit; }}
.pause-vpit { .navbar-default { border-color: @theme-color-dark-vpit; }}
.pause-vpit { .navbar-default { a:hover,a:active { color: @theme-color-darkest-vpit; }}}
.pause-vpit { .navbar-default .navbar-nav { li { a:hover,a:active { color: @theme-color-darkest-vpit; }}}}
