@theme-color-lbrocard: #ff7300;
@theme-color-highlight-lbrocard: darken(spin(@theme-color-lbrocard, -60), 30%);
@theme-color-lightest-lbrocard: lighten(@theme-color-lbrocard, (95% - lightness(@theme-color-lbrocard)));
@theme-color-lighter-lbrocard: lighten(@theme-color-lbrocard, (90% - lightness(@theme-color-lbrocard)));
@theme-color-light-lbrocard: lighten(@theme-color-lbrocard, (85% - lightness(@theme-color-lbrocard)));
@theme-color-darkest-lbrocard: darken(@theme-color-lbrocard, 40%);
@theme-color-darker-lbrocard: darken(@theme-color-lbrocard, 20%);
@theme-color-dark-lbrocard: darken(@theme-color-lbrocard, 15%);
@link-color-lbrocard: @theme-color-dark-lbrocard;
@link-hover-color-lbrocard: @theme-color-darker-lbrocard;
@headings-color-lbrocard: @theme-color-darkest-lbrocard;
@table-border-color-lbrocard: @theme-color-light-lbrocard;
@table-bg-accent-lbrocard: @theme-color-lightest-lbrocard;
@table-bg-hover-lbrocard: @theme-color-lightest-lbrocard;
@breadcrumb-bg-lbrocard: @theme-color-lightest-lbrocard;
@breadcrumb-color-lbrocard: @link-color-lbrocard;
@breadcrumb-active-color-lbrocard: @theme-color-highlight-lbrocard;
.pause-lbrocard { a { color:  @link-color-lbrocard; }}
.pause-lbrocard { a:hover { color:  @link-hover-color-lbrocard; }}
.pause-lbrocard { h1,h2,h3,h4,h5,h6 { color: @headings-color-lbrocard; }}
.pause-lbrocard { .table th,.table td { border: 1px solid @table-border-color-lbrocard; }}
.pause-lbrocard { .table tbody + tbody { border-top: 2px solid @table-border-color-lbrocard; }}
.pause-lbrocard { .table-bordered { border: 1px solid @table-border-color-lbrocard; }}
.pause-lbrocard { .table-bordered th, .table-bordered td { border: 1px solid @table-border-color-lbrocard; }}
.pause-lbrocard { .table-striped tbody > tr:nth-child(even) > td, .table-striped tbody > tr:nth-child(even) > th { background-color: @table-bg-accent-lbrocard; }}
.pause-lbrocard { .table-hover tbody > tr:hover td, .table-hover tbody tr:hover > th { background-color: @table-bg-hover-lbrocard; }}
.pause-lbrocard { table.tablesorter thead tr th, table.tablesorter thoot tr th { background-color: @theme-color-lighter-lbrocard; }}
.pause-lbrocard { table.tablesorter { border-color: @theme-color-lighter-lbrocard; }}
.pause-lbrocard { .breadcrumb { background-color: @breadcrumb-bg-lbrocard; }}
.pause-lbrocard { .breadcrumb { .active { color: @breadcrumb-active-color-lbrocard; }}}
.pause-lbrocard { .alert-info { color: @theme-color-dark-lbrocard; }}
.pause-lbrocard { .alert-info { background-color: @theme-color-lightest-lbrocard; }}
.pause-lbrocard { .alert-info { border-color: @theme-color-light-lbrocard; }}
.pause-lbrocard { .navbar-default { background-color: @theme-color-lbrocard; }}
.pause-lbrocard { .navbar-default { border-color: @theme-color-dark-lbrocard; }}
.pause-lbrocard { .navbar-default { a:hover,a:active { color: @theme-color-darkest-lbrocard; }}}
.pause-lbrocard { .navbar-default .navbar-nav { li { a:hover,a:active { color: @theme-color-darkest-lbrocard; }}}}
