@theme-color-rjbs: #bb00ff;
@theme-color-highlight-rjbs: darken(spin(@theme-color-rjbs, -60), 30%);
@theme-color-lightest-rjbs: lighten(@theme-color-rjbs, (95% - lightness(@theme-color-rjbs)));
@theme-color-lighter-rjbs: lighten(@theme-color-rjbs, (90% - lightness(@theme-color-rjbs)));
@theme-color-light-rjbs: lighten(@theme-color-rjbs, (85% - lightness(@theme-color-rjbs)));
@theme-color-darkest-rjbs: darken(@theme-color-rjbs, 40%);
@theme-color-darker-rjbs: darken(@theme-color-rjbs, 20%);
@theme-color-dark-rjbs: darken(@theme-color-rjbs, 15%);
@link-color-rjbs: @theme-color-dark-rjbs;
@link-hover-color-rjbs: @theme-color-darker-rjbs;
@headings-color-rjbs: @theme-color-darkest-rjbs;
@table-border-color-rjbs: @theme-color-light-rjbs;
@table-bg-accent-rjbs: @theme-color-lightest-rjbs;
@table-bg-hover-rjbs: @theme-color-lightest-rjbs;
@breadcrumb-bg-rjbs: @theme-color-lightest-rjbs;
@breadcrumb-color-rjbs: @link-color-rjbs;
@breadcrumb-active-color-rjbs: @theme-color-highlight-rjbs;
.pause-rjbs { a { color:  @link-color-rjbs; }}
.pause-rjbs { a:hover { color:  @link-hover-color-rjbs; }}
.pause-rjbs { h1,h2,h3,h4,h5,h6 { color: @headings-color-rjbs; }}
.pause-rjbs { .table th,.table td { border: 1px solid @table-border-color-rjbs; }}
.pause-rjbs { .table tbody + tbody { border-top: 2px solid @table-border-color-rjbs; }}
.pause-rjbs { .table-bordered { border: 1px solid @table-border-color-rjbs; }}
.pause-rjbs { .table-bordered th, .table-bordered td { border: 1px solid @table-border-color-rjbs; }}
.pause-rjbs { .table-striped tbody > tr:nth-child(even) > td, .table-striped tbody > tr:nth-child(even) > th { background-color: @table-bg-accent-rjbs; }}
.pause-rjbs { .table-hover tbody > tr:hover td, .table-hover tbody tr:hover > th { background-color: @table-bg-hover-rjbs; }}
.pause-rjbs { table.tablesorter thead tr th, table.tablesorter thoot tr th { background-color: @theme-color-lighter-rjbs; }}
.pause-rjbs { table.tablesorter { border-color: @theme-color-lighter-rjbs; }}
.pause-rjbs { .breadcrumb { background-color: @breadcrumb-bg-rjbs; }}
.pause-rjbs { .breadcrumb { .active { color: @breadcrumb-active-color-rjbs; }}}
.pause-rjbs { .alert-info { color: @theme-color-dark-rjbs; }}
.pause-rjbs { .alert-info { background-color: @theme-color-lightest-rjbs; }}
.pause-rjbs { .alert-info { border-color: @theme-color-light-rjbs; }}
.pause-rjbs { .navbar-default { background-color: @theme-color-rjbs; }}
.pause-rjbs { .navbar-default { border-color: @theme-color-dark-rjbs; }}
.pause-rjbs { .navbar-default { a:hover,a:active { color: @theme-color-darkest-rjbs; }}}
.pause-rjbs { .navbar-default .navbar-nav { li { a:hover,a:active { color: @theme-color-darkest-rjbs; }}}}
