@theme-color-book: #ff66cc;
@theme-color-highlight-book: darken(spin(@theme-color-book, -60), 30%);
@theme-color-lightest-book: lighten(@theme-color-book, (95% - lightness(@theme-color-book)));
@theme-color-lighter-book: lighten(@theme-color-book, (90% - lightness(@theme-color-book)));
@theme-color-light-book: lighten(@theme-color-book, (85% - lightness(@theme-color-book)));
@theme-color-darkest-book: darken(@theme-color-book, 40%);
@theme-color-darker-book: darken(@theme-color-book, 20%);
@theme-color-dark-book: darken(@theme-color-book, 15%);
@link-color-book: @theme-color-dark-book;
@link-hover-color-book: @theme-color-darker-book;
@headings-color-book: @theme-color-darkest-book;
@table-border-color-book: @theme-color-light-book;
@table-bg-accent-book: @theme-color-lightest-book;
@table-bg-hover-book: @theme-color-lightest-book;
@breadcrumb-bg-book: @theme-color-lightest-book;
@breadcrumb-color-book: @link-color-book;
@breadcrumb-active-color-book: @theme-color-highlight-book;
.pause-book { a { color:  @link-color-book; }}
.pause-book { a:hover { color:  @link-hover-color-book; }}
.pause-book { h1,h2,h3,h4,h5,h6 { color: @headings-color-book; }}
.pause-book { .table th,.table td { border: 1px solid @table-border-color-book; }}
.pause-book { .table tbody + tbody { border-top: 2px solid @table-border-color-book; }}
.pause-book { .table-bordered { border: 1px solid @table-border-color-book; }}
.pause-book { .table-bordered th, .table-bordered td { border: 1px solid @table-border-color-book; }}
.pause-book { .table-striped tbody > tr:nth-child(even) > td, .table-striped tbody > tr:nth-child(even) > th { background-color: @table-bg-accent-book; }}
.pause-book { .table-hover tbody > tr:hover td, .table-hover tbody tr:hover > th { background-color: @table-bg-hover-book; }}
.pause-book { table.tablesorter thead tr th, table.tablesorter thoot tr th { background-color: @theme-color-lighter-book; }}
.pause-book { table.tablesorter { border-color: @theme-color-lighter-book; }}
.pause-book { .breadcrumb { background-color: @breadcrumb-bg-book; }}
.pause-book { .breadcrumb { .active { color: @breadcrumb-active-color-book; }}}
.pause-book { .alert-info { color: @theme-color-dark-book; }}
.pause-book { .alert-info { background-color: @theme-color-lightest-book; }}
.pause-book { .alert-info { border-color: @theme-color-light-book; }}
.pause-book { .navbar-default { background-color: @theme-color-book; }}
.pause-book { .navbar-default { border-color: @theme-color-dark-book; }}
.pause-book { .navbar-default { a:hover,a:active { color: @theme-color-darkest-book; }}}
.pause-book { .navbar-default .navbar-nav { li { a:hover,a:active { color: @theme-color-darkest-book; }}}}
