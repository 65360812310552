@theme-color-barbie: #663376;
@theme-color-highlight-barbie: darken(spin(@theme-color-barbie, -60), 30%);
@theme-color-lightest-barbie: lighten(@theme-color-barbie, (95% - lightness(@theme-color-barbie)));
@theme-color-lighter-barbie: lighten(@theme-color-barbie, (90% - lightness(@theme-color-barbie)));
@theme-color-light-barbie: lighten(@theme-color-barbie, (85% - lightness(@theme-color-barbie)));
@theme-color-darkest-barbie: darken(@theme-color-barbie, 40%);
@theme-color-darker-barbie: darken(@theme-color-barbie, 20%);
@theme-color-dark-barbie: darken(@theme-color-barbie, 15%);
@link-color-barbie: @theme-color-dark-barbie;
@link-hover-color-barbie: @theme-color-darker-barbie;
@headings-color-barbie: @theme-color-darkest-barbie;
@table-border-color-barbie: @theme-color-light-barbie;
@table-bg-accent-barbie: @theme-color-lightest-barbie;
@table-bg-hover-barbie: @theme-color-lightest-barbie;
@breadcrumb-bg-barbie: @theme-color-lightest-barbie;
@breadcrumb-color-barbie: @link-color-barbie;
@breadcrumb-active-color-barbie: @theme-color-highlight-barbie;
.pause-barbie { a { color:  @link-color-barbie; }}
.pause-barbie { a:hover { color:  @link-hover-color-barbie; }}
.pause-barbie { h1,h2,h3,h4,h5,h6 { color: @headings-color-barbie; }}
.pause-barbie { .table th,.table td { border: 1px solid @table-border-color-barbie; }}
.pause-barbie { .table tbody + tbody { border-top: 2px solid @table-border-color-barbie; }}
.pause-barbie { .table-bordered { border: 1px solid @table-border-color-barbie; }}
.pause-barbie { .table-bordered th, .table-bordered td { border: 1px solid @table-border-color-barbie; }}
.pause-barbie { .table-striped tbody > tr:nth-child(even) > td, .table-striped tbody > tr:nth-child(even) > th { background-color: @table-bg-accent-barbie; }}
.pause-barbie { .table-hover tbody > tr:hover td, .table-hover tbody tr:hover > th { background-color: @table-bg-hover-barbie; }}
.pause-barbie { table.tablesorter thead tr th, table.tablesorter thoot tr th { background-color: @theme-color-lighter-barbie; }}
.pause-barbie { table.tablesorter { border-color: @theme-color-lighter-barbie; }}
.pause-barbie { .breadcrumb { background-color: @breadcrumb-bg-barbie; }}
.pause-barbie { .breadcrumb { .active { color: @breadcrumb-active-color-barbie; }}}
.pause-barbie { .alert-info { color: @theme-color-dark-barbie; }}
.pause-barbie { .alert-info { background-color: @theme-color-lightest-barbie; }}
.pause-barbie { .alert-info { border-color: @theme-color-light-barbie; }}
.pause-barbie { .navbar-default { background-color: @theme-color-barbie; }}
.pause-barbie { .navbar-default { border-color: @theme-color-dark-barbie; }}
.pause-barbie { .navbar-default { a:hover,a:active { color: @theme-color-darkest-barbie; }}}
.pause-barbie { .navbar-default .navbar-nav { li { a:hover,a:active { color: @theme-color-darkest-barbie; }}}}
