@theme-color-pjcj: #71558e;
@theme-color-highlight-pjcj: darken(spin(@theme-color-pjcj, -60), 30%);
@theme-color-lightest-pjcj: lighten(@theme-color-pjcj, (95% - lightness(@theme-color-pjcj)));
@theme-color-lighter-pjcj: lighten(@theme-color-pjcj, (90% - lightness(@theme-color-pjcj)));
@theme-color-light-pjcj: lighten(@theme-color-pjcj, (85% - lightness(@theme-color-pjcj)));
@theme-color-darkest-pjcj: darken(@theme-color-pjcj, 40%);
@theme-color-darker-pjcj: darken(@theme-color-pjcj, 20%);
@theme-color-dark-pjcj: darken(@theme-color-pjcj, 15%);
@link-color-pjcj: @theme-color-dark-pjcj;
@link-hover-color-pjcj: @theme-color-darker-pjcj;
@headings-color-pjcj: @theme-color-darkest-pjcj;
@table-border-color-pjcj: @theme-color-light-pjcj;
@table-bg-accent-pjcj: @theme-color-lightest-pjcj;
@table-bg-hover-pjcj: @theme-color-lightest-pjcj;
@breadcrumb-bg-pjcj: @theme-color-lightest-pjcj;
@breadcrumb-color-pjcj: @link-color-pjcj;
@breadcrumb-active-color-pjcj: @theme-color-highlight-pjcj;
.pause-pjcj { a { color:  @link-color-pjcj; }}
.pause-pjcj { a:hover { color:  @link-hover-color-pjcj; }}
.pause-pjcj { h1,h2,h3,h4,h5,h6 { color: @headings-color-pjcj; }}
.pause-pjcj { .table th,.table td { border: 1px solid @table-border-color-pjcj; }}
.pause-pjcj { .table tbody + tbody { border-top: 2px solid @table-border-color-pjcj; }}
.pause-pjcj { .table-bordered { border: 1px solid @table-border-color-pjcj; }}
.pause-pjcj { .table-bordered th, .table-bordered td { border: 1px solid @table-border-color-pjcj; }}
.pause-pjcj { .table-striped tbody > tr:nth-child(even) > td, .table-striped tbody > tr:nth-child(even) > th { background-color: @table-bg-accent-pjcj; }}
.pause-pjcj { .table-hover tbody > tr:hover td, .table-hover tbody tr:hover > th { background-color: @table-bg-hover-pjcj; }}
.pause-pjcj { table.tablesorter thead tr th, table.tablesorter thoot tr th { background-color: @theme-color-lighter-pjcj; }}
.pause-pjcj { table.tablesorter { border-color: @theme-color-lighter-pjcj; }}
.pause-pjcj { .breadcrumb { background-color: @breadcrumb-bg-pjcj; }}
.pause-pjcj { .breadcrumb { .active { color: @breadcrumb-active-color-pjcj; }}}
.pause-pjcj { .alert-info { color: @theme-color-dark-pjcj; }}
.pause-pjcj { .alert-info { background-color: @theme-color-lightest-pjcj; }}
.pause-pjcj { .alert-info { border-color: @theme-color-light-pjcj; }}
.pause-pjcj { .navbar-default { background-color: @theme-color-pjcj; }}
.pause-pjcj { .navbar-default { border-color: @theme-color-dark-pjcj; }}
.pause-pjcj { .navbar-default { a:hover,a:active { color: @theme-color-darkest-pjcj; }}}
.pause-pjcj { .navbar-default .navbar-nav { li { a:hover,a:active { color: @theme-color-darkest-pjcj; }}}}
